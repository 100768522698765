import { defineStore } from "pinia";
import { ref } from "vue";
import { UserInfoInStore } from "@/types/common";
import { getMyInfoApi } from "@/api/auth";
import { PermissionInStore } from "@/types/common";
import number from "async-validator/dist-types/validator/number";

export const useUserStore = defineStore(
  "user",
  () => {
    const token = ref<string | null>(null);
    const userInfo = ref<UserInfoInStore | null>(null);
    const permissions = ref<Array<PermissionInStore>>([]);
    const currentRole = ref<null | number>(null);
    const lastTokenTime=ref<null|number>(null);
    const isLogin = () => {
      const nowTime = new Date().getTime();
      if(lastTokenTime.value)
      {
        if(nowTime.valueOf() - lastTokenTime.value > 1000 * 60*60*12){
          return false;
        }
      }
      return !!token.value;
    };

    const refreshUserInfo = async () => {
      if (!isLogin()) return;
      return getMyInfoApi().then((res) => {
        userInfo.value = {
          email: res.data!.email,
          role: res.data!.role,
          userId: res.data!.id,
          userName: res.data!.name,
          userPoints: res.data!.points,
        };
      });
    };

    return {
      userInfo,
      token,
      lastTokenTime,
      permissions,
      isLogin,
      refreshUserInfo,
      currentRole,
    };
  },
  {
    persist: true,
  }
);
