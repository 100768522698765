import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  useRoute,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/views/mainView.vue"),
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: "/manage",
    component: () => import("@/views/manage/main.vue"),
    meta: {
      requiresLogin: true,
      requiresAdmin:true,
    },
    redirect: "/manage/pavilion",
    children: [
      {
        path: "pavilion",
        component: () => import("@/views/manage/pavilion/pavilionList.vue"),
      },
      {
        path: "pavilion-info",
        component: () => import("@/views/manage/pavilion/pavilionInfo.vue"),
      },
      {
        path: "talk",
        component: () => import("@/views/manage/talk/talkList.vue"),
      },
      {
        path: "npc",
        component: () => import("@/views/manage/npc/npcList.vue"),
      },
      {
        path: "resource",
        component: () => import("@/views/manage/resource/resourceList.vue"),
      },
      {
        path: "mail",
        component: () => import("@/views/manage/mail/mailList.vue"),
      },
      {
        path: "feedback",
        component: () => import("@/views/manage/feedback/feedbackList.vue"),
      },
      {
        path: "setting",
        component: () => import("@/views/manage/setting/settingList.vue"),
      },
      {
        path: "visitor",
        component: () => import("@/views/manage/visitor/visitorList.vue"),
      },
    ],
  },
  {
    path: "/navbar",
    component: () => import("../components/navBar.vue"),
    children: [
      {
        path: "/talkManagement",
        component: () => import("../views/talkManagement.vue"),
      },
      {
        path: "/talkDetails",
        component: () => import("../views/talkDetails.vue"),
      },
      {
        path: "/questionManagement",
        component: () => import("../views/questionManagement.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("../views/loginView.vue"),
  },
  {
    path: "/forget",
    component: () => import("../views/forgetView.vue"),
  },
  {
    path: "/register",
    component: () => import("../views/registerView.vue"),
  },
  {
    path: "/selectRole",
    component: () => import("../views/selectRole.vue"),
    meta: {
      requiresLogin: true,
    },
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

export const getActive = (maxLevel = 4): string => {
  const route = useRoute();
  if (!route.path) {
    return "";
  }
  return route.path
    .split("/")
    .filter((_item: string, index: number) => index <= maxLevel && index > 0)
    .map((item: string) => `/${item}`)
    .join("");
};
