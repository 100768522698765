import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import { storeToRefs } from "pinia";
import router from "@/router";
import type { CommonError } from "@/api/typing/common";
import { useUserStore } from "@/stores/userStore";
import { ElNotification } from "element-plus";

/**
 * 域名
 */
export const BaseUrl = "";

/**
 * 统一错误处理
 * @param error 错误信息
 * @returns 下一步操作
 */
const onErrorHappen: (error: any) => Promise<CommonError | undefined> = async (
  error: any
) => {
  const userStore = useUserStore();
  const { token } = storeToRefs(userStore);
  if (error.response) {
    if (
      error.response.status === 401 ||
      error.response.data.statusCode === 401000
    ) {
      ElNotification.error("你还没有登录！即将跳转至登录页！");
      token.value = "";
      setTimeout(async () => {
        await router.replace({ name: "loginForm", path: "/app/login" });
      }, 3000);
    } else if (
      error.response.status === 403 ||
      error.response.data.statusCode === 403000
    ) {
      ElNotification.error(
        "你没有权限执行此操作！若你有未保存的内容请保存至其它地方，否则可能会丢失！"
      );
    } else {
      return Promise.reject<CommonError>({
        statusCode: error.response.data.statusCode,
        message: error.response.data.message,
        data: error.response.data.data,
        httpErrorMessage: error.message,
        httpStatusCode: error.response.status,
      });
    }
  }
};

const onFulFilled = (response: AxiosResponse) => {
  if (response.data.statusCode === 200000) {
    return response.data;
  } else {
    return Promise.reject({
      statusCode: response.data.statusCode,
      message: response.data.message,
      data: response.data.data,
      httpErrorMessage: response.statusText,
      httpStatusCode: response.status,
    });
  }
};

/**
 * 发送普通网络请求
 * @param config 请求参数
 */
export function request<TResult, TParams>(config: AxiosRequestConfig<TParams>) {
  const query = axios.create({
    withCredentials: true,
    baseURL: BaseUrl,
  });
  query.interceptors.response.use(onFulFilled, onErrorHappen);
  return query.request<TResult, TResult, TParams>(config);
}

/**
 * 发送用户请求
 * @param config 请求参数
 */
export function userRequest<TResult, TParams>(
  config: AxiosRequestConfig<TParams>
) {
  const userStore = useUserStore();
  const { token } = storeToRefs(userStore);
  const query = axios.create({
    withCredentials: true,
    baseURL: BaseUrl,
    headers: {
      Authorization: `bearer ${token.value}`,
    },
  });
  query.interceptors.response.use(onFulFilled, onErrorHappen);
  return query.request<TResult, TResult, TParams>(config);
}

/**
 * 发送postForm请求
 * @param config 请求参数
 */
export function formRequest<TResult, TParams>(config: AxiosRequestConfig) {
  const formData = new FormData();
  const query = axios.create({
    withCredentials: true,
    method: "POST",
    baseURL: BaseUrl,
    headers: {
      "content-type": "application/x-www-formElement-urlencoded",
    },
  });
  query.interceptors.response.use(onFulFilled, onErrorHappen);

  for (const keys in config.data) {
    formData.append(keys, config.data[keys]);
  }
  config.data = formData;

  return query.request<TResult, TResult, TParams>(config);
}

/**
 * 发送用户表单请求
 * @param config 请求参数
 */
export function userFormRequest<TResult, TParams>(config: AxiosRequestConfig) {
  const formData = new FormData();
  const userStore = useUserStore();
  const { token } = storeToRefs(userStore);
  const query = axios.create({
    withCredentials: true,
    method: "POST",
    baseURL: BaseUrl,
    headers: {
      Authorization: `bearer ${token.value}`,
      "content-type": "application/x-www-form-urlencoded",
    },
  });
  query.interceptors.response.use(onFulFilled, onErrorHappen);

  for (const keys in config.data) {
    formData.append(keys, config.data[keys]);
  }
  config.data = formData;

  return query.request<TResult, TResult, TParams>(config);
}

/**
 * 下载文件
 */
export function downloadFiles(filePath: string, fileName: string) {
  fetch(filePath)
    .then((res) => {
      if (res.status === 200) return res.blob();
    })
    .then((blob: Blob | undefined) => {
      if (!blob) return;
      downloadBlob(blob, fileName);
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * GET 携带 Token 下载文件
 */
export function downloadFileWithToken(filePath: string, fileName: string) {
  const userStore = useUserStore();
  const { token } = storeToRefs(userStore);
  const query = axios.create({
    withCredentials: true,
    baseURL: BaseUrl,
    responseType: "blob",
    headers: {
      Authorization: `bearer ${token.value}`,
    },
  });

  query
    .request({
      url: filePath,
      method: "GET",
    })
    .then((response) => {
      downloadBlob(new Blob([response.data]), fileName);
    })
    .catch((err) => {
      console.log(err);
    });
}

function downloadBlob(blob: Blob, fileName: string) {
  const downloadHttpTagA = document.createElement("a");
  downloadHttpTagA.href = URL.createObjectURL(blob);
  downloadHttpTagA.download = fileName;
  document.body.appendChild(downloadHttpTagA); //追加a标签
  downloadHttpTagA.click(); //a标签点击
  document.body.removeChild(downloadHttpTagA); //移除节点
}
