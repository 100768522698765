import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from "./router";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "vant/lib/index.css";
import Vant from "vant";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

const app = createApp(App);

//添加fontawesome
library.add(fas, far, fab);
app.component("font-awesome-icon", FontAwesomeIcon);

app.use(ElementPlus, {
  locale: zhCn,
});
app.use(router);
app.use(Vant);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

// 权限路由守卫
import "@/scripts/permission";

app.mount("#app");
