import { useUserStore } from "@/stores/userStore";
import router from "@/router";
import { ElNotification } from "element-plus";
import { storeToRefs } from "pinia";

router.beforeEach((to, from) => {
  const { isLogin } = useUserStore();
  const { userInfo, refreshUserInfo } = useUserStore();
  const { permissions } = storeToRefs(useUserStore());
  // 无需登录的允许跳转
  if (!to.meta.requiresLogin) {
    return true;
  } else {
    // 需要登录又没有登录的
    if (!isLogin()) {
      window.localStorage.clear();
      ElNotification.error("需要登录后才能访问！");
      return { path: "/login" };
    }
  }
  // 无需管理员的允许跳转
  if (!to.meta.requiresAdmin) {
    return true;
  } else {
    // 需要管理员又不是的
    if (userInfo?.role != 1) {
      ElNotification.error("您没有权限访问该页面！");
      //刷新缓存
      refreshUserInfo();
      //如果是直接访问该页面的重定向至登录页
      if (from.matched.length === 0) {
        return { path: "/login" };
      }
      return false;
    }
  }
  //没有要求权限的允许跳转
  if (to.meta.permission === undefined || to.meta.permission === null) {
    return true;
  }
  //有要求权限的且有权限的允许跳转
  if (
    permissions.value &&
    permissions.value.find((value) => value.name === to.meta.permission) !==
      undefined
  ) {
    return true;
  } else {
    ElNotification.error("您没有权限访问该页面！");
    //如果是直接访问该页面的重定向至首页
    if (from.matched.length === 0) {
      return { path: "/" };
    }
    return false;
  }
});
