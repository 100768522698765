import type { AxiosRequestConfig } from "axios";
import { request, userRequest } from "@/utils/request";
import { CommonResponse } from "./typing/common";
import { LoginResult, UserInfoParams, UserInfoResult } from "./typing/auth";

export function loginApi(email: string, password: string) {
  const query: AxiosRequestConfig = {
    url: "/api/account/login",
    method: "POST",
    data: {
      email,
      password,
    },
  };
  return request<CommonResponse<LoginResult>, null>(query);
}

export function getMyInfoApi() {
  const query: AxiosRequestConfig = {
    url: "/api/account/me",
    method: "GET",
  };
  return userRequest<CommonResponse<UserInfoResult>, null>(query);
}

export function refreshApi() {
  const query: AxiosRequestConfig = {
    url: "/api/account/refresh",
    method: "POST",
  };
  return request<CommonResponse<LoginResult>, null>(query);
}

export function changeMyInfoApi(params: UserInfoParams) {
  const query: AxiosRequestConfig = {
    url: "/api/account/me",
    method: "POST",
    data:params,
  };
  return userRequest<CommonResponse<UserInfoResult>, null>(query);
}