<template>
  <router-view></router-view>
</template>

<script lang="ts" setup></script>

<style>
body {
  padding: 0;
  margin: 0;
}
</style>
